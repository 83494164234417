.banner {
  color: #fff;
  background-color: #262626;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .02rem;
  -webkit-font-smoothing: antialiased;

  img {
    vertical-align: middle;
  }
}

.title {
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 1.2px;
}

.uploadSection {
  background-color: #f4f4f4;
  max-width: 1300px;
  width: 100%;
  min-width: 1100px;
  margin: auto;
  position: relative;
  padding: 2rem;
}

.uploadWrapper {
  display: flex;
  direction: row;
  align-items: stretch;
}

.uploadHeader {
  color: white;
  background-color: black; 
  padding: 1rem;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;

  img {
    margin-left: 10px;
    vertical-align: bottom;
  }
}

.uploadFootage {
  width: 70%;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0,0,0,.19);
}

.uploadReleases {
  width: 30%;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0,0,0,.19);
}


