.typeaheadContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.suggestions {
  position: absolute;
  background-color: white;
  border: 1px solid black;
  width: 448px;
  margin-top: 0;
  padding: 0;
  box-shadow: 0 1px 5px rgba(0,0,0,.19);
  transition: .04s linear all;
  max-height: 300px;
  overflow-y: auto;

  li {
    list-style-type: none;
    padding: 0.4em;
    cursor: pointer;

    &:hover {
      background-color: #119a91;
    }
  }
}

.loader {
  display: inline-block;
  width: 40px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side,#119a91 90%,#0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}

.clearButton {
  position: absolute;
  right: 6px;
  bottom: 16px;
  /* button appearane */
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  appearance: none;
  border: none;
  border-radius: 50%;
  background: gray;
  margin: 0;
  padding: 2px;
  color: white;
  font-size: 13px;
  cursor: pointer;
  display: inline-flex;  
  &:hover {
    background: darkgray;
  }
}