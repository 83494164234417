.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #0000006A;
}

.timeoutContainer {
  text-align: center;
  padding: 58px 200px;
  background-color: #f4f4f4;
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    margin: 0;
    color: #333;
    font-size: 30px;
    letter-spacing: 1.5px;
    font-weight: bold;
    padding-bottom: 20px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-transform: uppercase;
  }

  .details {
    font-size: 13px;
    text-align: left;
    letter-spacing: .65px;
    line-height: 16px;
    color: #525252;
    font-weight: bold;
    margin-top: 46px;
  }
}

