$desktop: 1200px;
$laptop: 1024px;
$tablet: 768px;
$phone: 580px;
$custom-1: 529px;

.talent-description-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.description-section {
  margin-bottom: 30px;
  margin-top: 20px;
}

.section-title {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.section-button {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  border: 2px solid black;
  color: black;
  width: 100%;
  padding: 12px;
  text-align: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-button:hover {
  background-color: black;
  cursor: pointer;
  color: white;
}

.selected {
  background-color: black;
  color: white;
}

.top-options {
  display: flex;
  flex-direction: row;
  width: 100%;
  // margin-bottom: 10px;
  margin-top: 8px;
}

.left-padding {
  margin-left: 10px;
}

.individual-select-release {
  padding: 15px;
  border-bottom: 1px solid #757575;
}

.individual-select-release:hover {
  cursor: pointer;
  background-color: rgb(241, 241, 241);
}

.all-selects-release::-webkit-scrollbar {
  display: none;
}

.all-selects-release {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.all-selects-release {
  border-right: 1px solid #757575;
  border-left: 1px solid #757575;
  border-bottom: 1px solid #757575;
  margin-bottom: 15px;
  margin-top: -15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #757575;
  height: 203px;
  overflow-y: scroll;
}

.shorter_height {
  height: 100px;
}

.mid_height {
  height: 152px;
}

.all-selects-release div:last-child {
  border-bottom: none;
}

.no-shadow {
  -webkit-appearance: none;
}

.bottom-mobile {
  @media screen and (max-width: $tablet) {
    padding-bottom: 50px;
  }
}

.mobile-padding {
  @media screen and (max-width: $tablet) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.talent-background {
  background-color: #f2f2f2;
  width: 100%;
  box-sizing: border-box;
  padding: 35px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.property-background {
  width: 100%;
  box-sizing: border-box;
}

.white-background {
  background-color: white;
}

.save-talent {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #0ca5a7;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: white;
  width: 100%;
}

.save-talent:hover {
  cursor: pointer;
  background-color: #0dacac;
}

.save-talent-stale {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #8e8e8e;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: white;
  width: 100%;
}

.delete-talent {
  padding: 0px 20px;
  background-color: #c14949;
  color: white;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-talent:hover {
  cursor: pointer;
  background-color: #ce4d4d;
}

.talent-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}

.add-talent {
  background-color: #f2f2f2;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.add-talent:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.saved-talent {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background-color: #f2f2f2;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 20px;
  border: 1px solid #0dacac;
}

.saved-talent:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.lower-weight {
  font-weight: 400;
}

.guardian-padding {
  margin-top: 15px;
}

.backdrop {
  position: fixed;
  background-color: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 120px;
}

.crop-image {
  color: black;
  background-color: white;
  width: 200px;
  height: 100px;
}

.controls {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slider {
  width: 260px;
}

.controls-upper-area {
  width: 100%;
  display: flex;
  justify-content: center;
}

.crop-image-button {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #0dacac;
  color: white;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  margin-top: 15px;
  width: 100px;
  outline: none;
  border: none;
}

.crop-image-button:hover {
  cursor: pointer;
  background-color: #0eb6b6;
}

.image-buttons {
  display: flex;
  flex-direction: row;
}

.check-mark-container-release img {
  width: 60%;
  margin-left: -1px;
}

.check-mark-container-release {
  width: 600px;
  min-width: 60px;
  height: 60px;
  border: 4px solid #0ca6a7;
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.close-image-button {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #5f5f5f;
  color: white;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  margin-top: 15px;
  width: 100px;
  outline: none;
  border: none;
}

.close-image-button:hover {
  cursor: pointer;
  background-color: #636363;
}

.cropped-image {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}

.pic-upload-release {
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 0px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
  padding: 15px 14px 14px 14px;
  height: 50px;
  background-color: black;
  color: white;
  text-align: center;
}

.pic-upload-release:hover {
  cursor: pointer;
  background-color: rgb(24, 24, 24);
}

input[type="date"] {
  -webkit-appearance: none;
  background-color: white;
  min-height: 50px;
  color: black;
}

.checkmark-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.royalty-check-selected {
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: #0eb6b6;
  margin-top: 5px;
  margin-bottom: 20px;
}

.royalty-check-blank {
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: rgb(189, 189, 189);
  margin-top: 5px;
  margin-bottom: 20px;
}

.royalty-check-blank:hover,
.royalty-check-selected:hover {
  cursor: pointer;
}

.royalty-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  margin-top: -15px;
  margin-left: 8px;
}

.royalty-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.error {
  border-color: solid red 1px;
}
