$desktop: 1200px;
$laptop: 1024px;
$tablet: 768px;
$phone: 580px;

.all-individual-shoot {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  @media screen and (max-width: $phone) {
    padding-top: 15px;
  }
}

.shoot-name-banner {
  margin-left: 80px;
  margin-right: 80px;
  padding-left: 30px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px solid #e6e6e6;
  display: flex;
  flex-direction: row;
  background-color: none;
  transition: 0.3s;
  color: black;
  @media screen and (max-width: $phone) {
    margin-left: 0;
    margin-right: 0;
  }
}

.shoot-name-banner:hover {
  background-color: #f2f2f2;
  transition: 0.3s;
}

.shoot-name-header {
  margin-left: 80px;
  margin-right: 80px;
  padding-left: 30px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px solid #e6e6e6;
  display: flex;
  flex-direction: row;
  background-color: none;
  color: black;
  @media screen and (max-width: $phone) {
    margin-left: 0;
    margin-right: 0;
  }
}

.not-ready-individual {
  color: #d4d4d4;
  display: flex;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
}

.shoot-details-header {
  margin-left: 80px;
  margin-right: 80px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  color: black;
  @media screen and (max-width: $phone) {
    margin-left: 0;
    margin-right: 0;
  }
}

.arrow-back {
  display: flex;
  justify-content: center;
  padding-left: 12px;
  transform: scaleX(-1);
}

.shoot-title-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.shoot-title {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
}

.status {
  font-size: 20px;
  // margin-bottom: 30px;
}

.status-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.status-title-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.status-text-top {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin-left: 7px;
}

.shoot-name {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin-top: 4px;
}

.shoot-details-title {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  padding-bottom: 15px;
  padding-top: 17px;
  border-bottom: 1.5px solid #e6e6e696;
}

.grid-container-bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  padding-bottom: 15px;
  padding-top: 15px;
}

.grid-title {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1.5;
}

.grid-detail {
  color: #888888;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-top: 3.5px;
}

.notes-header {
  margin-left: 80px;
  margin-right: 80px;
  padding-left: 30px;
  border-top: 1.5px solid #e6e6e6;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  color: black;
  @media screen and (max-width: $phone) {
    margin-left: 0;
    margin-right: 0;
  }
}

.notes-title {
  font-size: 20px;
  margin-bottom: 15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.notes {
  line-height: 1.4;
  color: #888888;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.status-step {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  grid-column-gap: 5px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  padding-bottom: 25px;
  width: 100%;
  padding-left: 15px;
}

.status-step-bottom {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  grid-column-gap: 5px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  padding-bottom: 5px;
  padding-left: 15px;
}

.status-text {
  color: #888888;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.status-text-selected {
  color: black;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.status-circle {
  width: 12px;
  height: 12px;
  border: 4px solid black;
  border-radius: 20px;
  background-color: white;
  z-index: 5;
}

.status-circle-done {
  width: 12px;
  height: 12px;
  border: 3px solid black;
  border-radius: 20px;
  background-color: black;
  z-index: 5;
}

.status-circle-current {
  width: 18px;
  height: 18px;
  margin-left: -2.8px;
  margin-top: -1.7px;
  border: 3px solid black;
  border-radius: 20px;
  background-color: black;
  z-index: 5;
}

.progress-line {
  width: 3.2px;
  height: 285px;
  margin-top: 3px;
  background-color: black;
  position: relative;
  left: 26px;
  z-index: 1;
}

.progress-line-pre-approval {
  width: 3.2px;
  height: 150px;
  margin-top: 3px;
  background-color: black;
  position: relative;
  left: 26px;
  z-index: 1;
}

.progress-section {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 3px;
}

.status-completed {
  z-index: 5;
  margin-left: -3.5px;
  margin-top: -4px;
}

.blank {
  display: none;
}

.denied-section {
  display: flex;
  flex-direction: row;
}

.denied-circle {
  padding-right: 15px;
}

.denied-description {
  line-height: 1.4;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.top-error {
  display: flex;
  flex-direction: column;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.asset-reasons {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.individual-reason {
  padding-bottom: 15px;
  padding-top: 10px;
}

.regular-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

a:link,
a:hover,
a:active,
a:visited {
  color: black;
}

.individual-link {
  color: black;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.hold-error {
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
}

.hold-error-message {
  font-size: 17px;
  color: #c94d4d;
  padding-left: 10px;
  margin-top: 3px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.error-details {
  line-height: 1.3;
}

.footage-manager-button {
  width: 100%;
  background-color: #289175;
  transition: 0.4s;
  height: 50px;
  border-radius: 5px;
  color: white !important;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;
  text-decoration: none;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.footage-manager-button:hover {
  background-color: #2fa585;
  transition: 0.4s;
}

.backlog-deny {
  margin-bottom: 20px;
}

.review-button {
  width: 100%;
  background-color: #000000;
  transition: 0.4s;
  height: 50px;
  border-radius: 5px;
  color: white !important;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  // margin-bottom: 10px;
  text-decoration: none;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.review-button:hover {
  background-color: rgb(34, 34, 34);
  cursor: pointer;
}

.upload-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
  margin-top: 10px;
}

.upload-text:hover {
  cursor: pointer;
}

.review-link-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.review-buttons {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }
}

.space-right {
  margin-left: 10px;
  @media screen and (max-width: $tablet) {
    margin-left: 0px;
  }
}

.feedback-date {
  margin-top: 15px;
}
