.successContainer {
  position: absolute;
  top: 0;
  min-width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 14px 0;
  color: white;
  background-color: #119a91;
  box-shadow: 0 2px 5px rgba(0,0,0,.4);

  p {
    margin: 0;
  }
}