$desktop: 1200px;
$laptop: 1024px;
$tablet: 768px;
$phone: 580px;
$custom-1: 529px;

html {
  scroll-behavior: smooth;
}

// .signature {
//   border: 2px solid black;
//   width: 400px;
//   height: 200px;
//   margin-top: 10px;
//   margin-bottom: 20px;
// }

// .signature:hover {
//   cursor: pointer;
// }

.revenue-agreement-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 15px;
  @media screen and (max-width: $phone) {
    padding: 15px 15px 35px 15px;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.state {
  margin-right: 15px;
}

.two-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.side-input {
  border: 1px solid #757575;
  padding: 14px;
  margin-bottom: 15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 42.5%;
  border-radius: 0;
}

.stale {
  color: #757575;
}

.custom-select {
  display: block;
  width: 100%;
}

.custom-select:hover {
  cursor: pointer;
}

.individual-select:hover,
.last-select:hover {
  cursor: pointer;
  background-color: rgb(241, 241, 241);
}

.all-selects::-webkit-scrollbar {
  display: none;
}

.all-selects {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.all-selects {
  border-right: 1px solid #757575;
  border-left: 1px solid #757575;
  border-bottom: 1px solid #757575;
  margin-bottom: 15px;
  margin-top: -15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #757575;
  height: 203px;
  overflow-y: scroll;
}

.individual-select {
  padding: 15px;
  border-bottom: 1px solid #757575;
}

.last-select {
  padding: 15px;
}

.down-arrow {
  width: 15px;
}

.up-arrow {
  width: 15px;
  transform: rotate(180deg);
}

.input {
  border: 1px solid #757575;
  padding: 14px;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  border-radius: 0;
  // border-radius: 3px;
}

.error {
  border: 1px solid red;
}

.correct {
  border: 1px solid #0ca6a7;
}

form {
  width: 100%;
  max-width: 500px;
}

.default {
  color: green;
}

.email-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 40px;
  margin-top: -6px;
}

.collaborator {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}

.address-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
  justify-content: flex-start;
  margin-bottom: 40px;
}

.logo {
  margin-right: 15px;
  background-color: black;
  width: 85px;
  height: 85px;
  color: white;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.address {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.agreement-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
}

.agreement-title {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}

.checkbox-section {
  display: flex;
  flex-direction: row;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.checkbox {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-selected {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  background-color: #0ca5a7;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(128, 128, 128, 0);
}

.checkbox:hover,
.checkbox-selected:hover {
  cursor: pointer;
}

.checkbox-text {
  font-size: 18px;
  line-height: 1.2;
}

.date-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
}

.check {
  box-sizing: border-box;
  width: 65%;
  filter: grayscale(100%) brightness(200%);
}

.agreement-link {
  text-decoration: underline;
}

.agreement-link:hover {
  cursor: pointer;
}

.submit-split-form {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  background-color: #0ca6a7;
  margin-top: 40px;
  font-size: 15px;
  outline: none;
  color: white;
}

.submit-split-form:hover {
  cursor: pointer;
  background-color: #0dacac;
}

.submit-stale-split-form {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  background-color: #f2f2f2;
  color: #747474;
  margin-top: 40px;
  font-size: 15px;
  outline: none;
}

.all-revenue {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-section-revenue {
  width: 100%;
  max-width: 400px;
  border: 2px solid #0dacac;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  box-sizing: border-box;
  @media screen and (max-width: $phone) {
    border: none;
    padding: 10px;
  }
}

.check-mark-container img {
  width: 60%;
  margin-left: -1px;
}

.check-mark-container {
  width: 60px;
  height: 60px;
  border: 4px solid #0ca6a7;
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.success-title-revenue {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 15px;
}

.success-text-revenue {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 17px;
}
