.uploadContainer {
  display: block;
  padding: 1rem;
}

.statusBar {
  display: block;
  background-color: #119a91;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  height: 26px;
  line-height: 24px;
  padding: 0 30px;
  font-size: 12px;
  letter-spacing: .05rem;
  -webkit-font-smoothing: antialiased;
}

.dropzone {
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  border: 2px dashed #aaa;
}


.uploadRow {
  display: flex;
  border: 1px solid #eee;
  border-width: 1px 0;
  padding: 0.5rem;
  position: relative;
  z-index: 0;
}

.filename {
  flex-grow: 1;
}

.size {
  width: 20%;
  font-size: 14px;
}

.elapsed {
  width: 20%;
  text-align: right;
}

.uploadProgress {
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  margin-top: -1px;
  display: block;
  overflow: hidden;
  transition: width .3s, opacity .3s linear;
  z-index: -1;
}

.uploadProgressStripes {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(-55deg, rgba(255, 255, 255, 0.075) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.075) 50%, rgba(255, 255, 255, 0.075) 75%, transparent 75%, transparent);
  background-size: 47px 67px;
  animation: move 3s linear infinite;
  overflow: hidden;
  border-radius: 3px;
}

.uploadButton {
  float: right;
  padding: 16px 0;

  button {
    font-weight: 600;
    height: 40px;
    padding: 0 10px;
    border: none;
    background-color: #0ca6a7;
    outline: none;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #0dacac;
    }

    &:disabled {
      background-color: #0dacac9f;
      cursor: not-allowed;
    }
  }
}

button.linkButton {
  background:none!important;
  border:none; 
  padding:0!important;
  font-family:arial,sans-serif; /*input has OS specific font-family*/
  font-size: inherit;
  color:#069;
  cursor:pointer;

  &:hover{
    text-decoration:underline;
  }
}

.uploaderTable {
  table-layout: fixed;
  border-collapse: collapse;
  position: relative;    
  overflow: hidden;

  th, td {
    border: 1px solid lightgray;
    padding: .5rem .5rem;
    word-wrap: break-word;
  }

  thead {
    vertical-align: bottom;
  }
}

.animationStripes {
  animation: progress 2s linear infinite;
  background-size: 100%, 150% 100%;
}

@keyframes progress{
  0% {
    background-position: 0 0, 0 0;
  }
  100% {
    background-position: 0 0, -70px 0px;
  }
}

.retryButton {
  font-weight: 600;
  border: none;
  background-color: #77aa48;
  font-size: 15px;
  outline: none;
  color: white;
  cursor: pointer;
}

.deleteButton {
  font-weight: 600;
  border: none;
  background-color: #aa4848;
  font-size: 15px;
  outline: none;
  color: white;
  cursor: pointer;
}

@keyframes move {
  0% {
    background-position: 43px 74px;
  }
  100% {
    background-position: 0 0;
  }
}