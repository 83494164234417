$desktop: 1200px;
$laptop: 1024px;
$tablet: 768px;
$phone: 580px;

.all {
  display: flex;
  justify-content: center;
  padding-top: 120px;
  @media screen and (max-width: $phone) {
    padding-top: 15px;
  }
}

.error {
  border-color: red;
}

.hide {
  display: none;
}

.container {
  width: 500px;
  padding: 50px;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 5px;
  @media screen and (max-width: $phone) {
    border: none;
    padding: 30px;
    width: 100%;
  }
}

.container-yellow {
  width: 500px;
  padding: 50px;
  border: 2px solid #be9b0e;
  border-radius: 5px;
  @media screen and (max-width: $phone) {
    border: none;
    padding: 30px;
    width: 100%;
  }
}

.container-green {
  width: 500px;
  padding: 50px;
  border: 2px solid #0dacac;
  border-radius: 5px;
  @media screen and (max-width: $phone) {
    border: none;
    padding: 30px;
    width: 100%;
  }
}

.form-title {
  text-align: center;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.confirmation-container,
.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.confirmation-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  width: 400px;
  line-height: 1.2;
  @media screen and (max-width: $phone) {
    width: 95%;
  }
}

.success-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  width: 250px;
  line-height: 1.2;
}

.important-header {
  margin-bottom: 6px;
}

.important-container {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 30px;
}

.royalty-recepients {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
}

.filmmaker-element {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-top: 40px;
}

.header-element {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin-top: 10px;
  line-height: 1.2;
}

.admin-name {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.filmmaker-royalty {
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  box-sizing: border-box;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  border: 1px solid rgba(255, 0, 0, 0);
}

.row-1-filmmaker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: $phone) {
    flex-direction: column;
  }
}

.row-1-payee {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.row-2-filmmaker {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.row-2-payee {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #be9b0e;
  line-height: 1.2;
}

.save-filmmaker-stale {
  width: 32.4%;
  margin-top: 15px;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #8e8e8e;
  color: white;
  @media screen and (max-width: $phone) {
    width: 100%;
  }
}

.save-filmmaker {
  width: 32.4%;
  margin-top: 15px;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #0ca6a7;
  color: white;
  @media screen and (max-width: $phone) {
    width: 100%;
  }
}

.percentage-error {
  color: red;
}

.save-filmmaker:hover {
  cursor: pointer;
  background-color: #0dacac;
}

.filmmaker-input {
  border: none;
  height: 40px;
  width: 23%;
  outline: none;
  border-radius: 5px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid rgba(255, 0, 0, 0);
  -webkit-appearance: none;
  @media screen and (max-width: $phone) {
    width: 100%;
    margin-top: 15px;
    box-sizing: border-box;
    height: 45px;
  }
}

.add-royalty {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #f2f2f2;
  margin-top: 20px;
  outline: none;
  font-size: 15px;
  border: 1px solid rgba(255, 0, 0, 0);
}

.add-royalty:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.payee-default {
  width: 100%;
  min-height: 57px;
  padding: 20px 30px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 20px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.filmmaker-default {
  width: 100%;
  padding: 20px 30px;
  min-height: 57px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 20px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.2;
}

.filmmaker-confirmation {
  width: 100%;
  padding: 20px 10px;
  border-top: 1px solid #8d8d8d;
  border-bottom: 1px solid #8d8d8d;
  box-sizing: border-box;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.2;
}

.payee-confirmation {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  padding-bottom: 18px;
  border-bottom: 1px solid #8d8d8d;
  box-sizing: border-box;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  // max-height: 57px;
  // @media screen and (max-width: $phone) {
  //   max-height: 80px;
  // }
}

.payee-confirmation-row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payee-name {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.payee-default:hover,
.filmmaker-default:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.individual-royalty {
  width: 100%;
  padding: 30px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 20px;
  border: 1px solid rgba(255, 0, 0, 0);
}

.first-name,
.last-name {
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  width: 48.2%;
  border: none;
  border-radius: 5px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid rgba(255, 0, 0, 0);
  -webkit-appearance: none;
  @media screen and (max-width: $phone) {
    width: 100%;
  }
}

.last-name {
  @media screen and (max-width: $phone) {
    margin-top: 15px;
  }
}

.email {
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid rgba(255, 0, 0, 0);
  -webkit-appearance: none;
}

.percentage {
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid rgba(255, 0, 0, 0);
  -webkit-appearance: none;
  @media screen and (max-width: $phone) {
    width: 80%;
  }
}

.save-payee {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  background-color: #0ca6a7;
  border-radius: 5px;
  font-size: 15px;
  color: white;
  margin-left: 15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  @media screen and (max-width: $phone) {
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
    margin-top: 15px;
  }
}

.save-payee-stale {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  margin-left: 15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #8e8e8e;
  color: white;
  @media screen and (max-width: $phone) {
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
    margin-top: 15px;
  }
}

.save-payee:hover {
  cursor: pointer;
  background-color: #0dacac;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.row-3-mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.delete-royalty {
  height: 45px;
  padding: 0px 20px;
  border: none;
  outline: none;
  background-color: #c14949;
  border-radius: 5px;
  color: white;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $phone) {
    display: none;
  }
}

.delete-royalty-mobile {
  display: none;

  @media screen and (max-width: $phone) {
    display: flex;
    width: 22%;
    height: 45px;
    border: none;
    outline: none;
    background-color: #c14949;
    border-radius: 5px;
    color: white;
    margin-left: 15px;
    justify-content: center;
    align-items: center;
  }
}

.delete-royalty:hover {
  cursor: pointer;
  background-color: #ce4d4d;
}

.row-1 {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $phone) {
    flex-direction: column;
  }
}

.row-2,
.row-3 {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.row-3 {
  @media screen and (max-width: $phone) {
    flex-direction: column;
  }
}

.legal {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 30px;
}

.nav-buttons {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $phone) {
    flex-direction: column-reverse;
  }
}

.submit {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #0ca6a7;
  margin-top: 40px;
  font-size: 15px;
  outline: none;
  color: white;
}

.submit-stale {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #747474;
  margin-top: 40px;
  font-size: 15px;
  outline: none;
}

.back-to-footage-manager {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: black;
  margin-top: 40px;
  font-size: 15px;
  outline: none;
  margin-right: 20px;
  @media screen and (max-width: $phone) {
    margin-top: 15px;
  }
}

.footage-manager {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: black;
  margin-top: 30px;
  font-size: 15px;
  outline: none;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.regular-footage-manager {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: -6px;
}

.back-to-footage-manager:hover,
.footage-manager:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.submit:hover {
  cursor: pointer;
  background-color: #0dacac;
}

.error {
  border: 1px solid red;
}

.warning {
  border: 1px solid #be9b0e;
}

.correct {
  border: 1px solid #0ca6a7;
}

.error-section {
  margin-top: 30px;
  @media screen and (max-width: $phone) {
    margin-left: 5px;
  }
}

.list-error {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: red;
  margin-top: 10px;
  line-height: 1.2;
}

.none {
  display: none;
}

.invisible {
  opacity: 0;
}

.warning-container {
  width: 20px;
  height: 100%;
}

.warning-container img {
  width: 100%;
  margin-top: 5px;
  margin-left: 6px;
}

.warning-container img:hover {
  cursor: pointer;
}

.back-arrow-container {
  width: 15px;
  height: 100%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.back-arrow-container img {
  width: 100%;
  margin-top: 1px;
  margin-left: 6px;
}

.payee-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 1.2;
}

.payee-email {
  margin-left: 5px;
}

.trash-container {
  width: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trash-container img {
  width: 100%;
}

.check-mark-container {
  width: 60px;
  height: 60px;
  border: 4px solid #0ca6a7;
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.check-mark-container img {
  width: 60%;
  margin-left: -1px;
}

.success-header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.spinner-individual {
  min-width: 20px;
  min-height: 20px;
  border: 5px solid rgba(255, 255, 255, 0);
  margin: auto;
  width: 0%;
  border-right: 5px solid #0ca6a7;
  border-bottom: 5px solid #0ca6a7;
  border-radius: 50%;
  animation: spinner 1.5151515151515151s linear infinite;
}

.spinner-submit {
  min-width: 12px;
  min-height: 12px;
  border: 4px solid rgba(255, 255, 255, 0);
  margin: auto;
  width: 0%;
  border-right: 4px solid #ffffff;
  border-bottom: 4px solid #ffffff;
  border-radius: 50%;
  animation: spinner 1.5151515151515151s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.collaborator-input {
  border: none;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: -20px;
  outline: none;
  border-radius: 5px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid rgba(255, 0, 0, 0);
  -webkit-appearance: none;
  @media screen and (max-width: $phone) {
    width: 100%;
    margin-top: 15px;
    box-sizing: border-box;
    height: 45px;
  }
}

.update-container {
  width: 400px;
  padding: 50px;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 5px;
  @media screen and (max-width: $phone) {
    border: none;
    padding: 30px;
    width: 100%;
  }
}

.update-container-green {
  width: 400px;
  padding: 50px;
  border: 2px solid #0dacac;
  border-radius: 5px;
  @media screen and (max-width: $phone) {
    border: none;
    padding: 30px;
    width: 100%;
  }
}

.update-email-error {
  margin-top: 25px;
}

.update-email-error-text {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #be9b0e;
  line-height: 1.2;
}

.update-email {
  text-decoration: underline;
}

.update-email:hover {
  cursor: pointer;
}

.update-email-button {
  width: 32.4%;
  margin-top: 15px;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #0ca6a7;
  color: white;
  @media screen and (max-width: $phone) {
    width: 100%;
  }
}

.spinner-top {
  margin-top: 25px;
}
