.button {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  background-color: #0ca6a7;
  margin-top: 15px;
  font-size: 15px;
  outline: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &.gray {
    background-color: #c4c4c4;
  }

  &.black {
    background-color: #262626;
    color: white;
  }
}