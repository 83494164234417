@keyframes ldio-949b26oganr {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-949b26oganr div {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 9px solid #0ca5a7;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-949b26oganr div {
  animation: ldio-949b26oganr 1.5151515151515151s linear infinite;
  margin-top: 230px;
  
}
.loadingio-spinner-rolling-75vipoblcv5 {
  width: 170px;
  height: 170px;
  display: inline-block;
  margin-top: 100px;
  overflow: hidden;
  background: none;
  margin: auto;
}
.ldio-949b26oganr div { box-sizing: content-box; }

.spinner {
  margin: auto;
  width: 0%
}
