.warning {
  margin: 0 auto;
  width: 714px;

  background-color: #e3e3e3;
  box-shadow: 0 1px 5px rgba(0,0,0,.19);
  transition: .04s linear all;

  header {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    padding: 0 30px;
    background-color: rgb(209, 159, 24);
    color: #fff;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .9px;

    img {
      height: 20px;
      margin-right: 10px;
      vertical-align: text-bottom;
    }
  }
}

.warningBody {
  padding: 0px 30px 8px;
  margin-top: 28px;
  line-height: 25px;
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  flex-grow: 1;

  p {
    font-size: 14px;
    line-height: 17px;
  }
}

.warningFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  margin: 0 auto;
  padding: 0 30px 30px;
}