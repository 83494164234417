$desktop: 1200px;
$laptop: 1024px;
$tablet: 768px;
$phone: 580px;
$custom-1: 529px;

.pic-upload {
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 0px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 15px 14px 14px 14px;
  height: 50px;
  background-color: black;
  color: white;
  text-align: center;
}

.pic-upload:hover {
  cursor: pointer;
  background-color: rgb(24, 24, 24);
}

.pic-replace {
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 0px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 15px 14px 14px 14px;
  margin-bottom: 15px;
  height: 50px;
  background-color: #0ca6a7;
  color: white;
  text-align: center;
}

.pic-replace:hover {
  cursor: pointer;
  background-color: #0dafaf;
}

.pic-error {
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 0px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 15px 14px 14px 14px;
  margin-bottom: 15px;
  height: 50px;
  background-color: rgb(216, 0, 0);
  color: white;
  text-align: center;
}

.pic-error:hover {
  cursor: pointer;
  background-color: rgb(230, 1, 1);
}

input[type="file"] {
  display: none;
}

.staged-picture {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 14px 20px;
  border: 1px solid #0ca6a7;
}

.staged-picture-error {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 14px 20px;
  border: 1px solid red;
}

.check-mark-pic {
  width: 25px;
}

.pic-name {
  margin-bottom: 5px;
}

.word-counter {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  text-align: right;
  margin-bottom: 15px;
}

.bio {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 1px;
  padding: 14px;
  height: 120px;
  padding-top: 10px;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.bio:focus {
  outline: none;
}

.application-label-bio {
  margin-top: 5px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
}

.success-section-profile {
  width: 100%;
  max-width: 425px;
  border: 2px solid #0dacac;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  box-sizing: border-box;
  @media screen and (max-width: $phone) {
    border: none;
    padding: 10px;
  }
}

.checkmark-container {
  display: flex;
  align-items: center;
}

.size-error {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: red;
  margin-top: 5px;
}
