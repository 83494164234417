body {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.block {
  display: block;
}

.application-all {
  width: 100%;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.application-title {
  font-weight: 600;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.application-form {
  max-width: 450px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.application-input-section {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.application-label {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
}

textarea {
  resize: vertical;
}

.application-input-text {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  font-weight: 400;
  font-size: 15px;
  padding: 14px;
}

.regular {
  border: 1px solid #757575;
}

.application-input-text-area {
  height: 150px;
  padding-top: 10px;
  padding: 14px;
}

.smaller-height {
  height: 100px;
}

.application-input-text:focus {
  outline: none;
}

.application-submit {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  background-color: #0ca6a7;
  margin-top: 15px;
  font-size: 15px;
  outline: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.application-warning {
  border: 1px solid #FF0000;
}

.application-submit-stale {
  width: 100%;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 50px;
  border: none;
  background-color: #c4c4c4;
  margin-top: 15px;
  font-size: 15px;
  outline: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.application-submit:hover {
  cursor: pointer;
  background-color: #0dacac;
}

.application-back-section {
  background-color: rgb(182, 182, 182);
}

.applicaiton-back {
  margin-bottom: 20px;
}

.application-arrow {
  margin-right: 20px;
  display: flex;
  align-items: center;

  margin-left: -5px;
  padding: 10px;
}

.application-arrow img {
  width: 100%;
  margin-top: 1px;
  margin-left: 6px;
  margin-left: -0.5px;
}
