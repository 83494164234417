$desktop: 1200px;
$laptop: 1024px;
$tablet: 768px;
$phone: 580px;

// body {
//   display: flex;
//   justify-content: center;
// }

.all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-block {
  margin-top: 30vh;
  background-color: black;
  width: 370px;
  padding: 30px;
  text-align: center;
  text-decoration: none;
  @media screen and (max-width: $phone) {
    width: 70%;
  }

  .white-link {
    color: white !important;
  }
}

.page-not-found-title {
  color: white;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 25px;
}

.description {
  color: white;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-top: 10px;
  line-height: 1.5;
  font-size: 17px;
}

a {
  color: white;
}

a:visited {
  color: white;
}
