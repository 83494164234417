.inaccurateData {
  color: #fff;
  background-color: #262626;
  display: block;
  margin: auto;
  width: 360px;
  padding: 23px 21px;
  text-align: center;
  margin-top: 150px;

  a {
    color: white;
  }
}