$desktop: 1200px;
$laptop: 1024px;
$tablet: 768px;
$phone: 580px;

body {
  background-color: #ffffff;
  margin: 0;
  padding-bottom: 120px;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  @media screen and (max-width: $phone) {
    padding-bottom: 0px;
  }
}

// body::-webkit-scrollbar {
//   display: none;
// }

.header {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sub-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 7px;
}

.beta {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background-color: white;
  font-size: 11px;
  padding: 5px 15px 5px 15px;
  border-radius: 30px;
  text-transform: uppercase;
}

.filmsupply-logo {
  text-transform: uppercase;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 26px;
  color: white;
}

.footage-tracker {
  margin-top: 3px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.hello {
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.profile-name {
  margin-top: 3px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 33px;
}

.contact-button {
  height: 37px;
  background-color: black;
  transition: 0.4s;
  color: white !important;
  padding-left: 23px;
  padding-right: 23px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 5px;
  text-decoration: none;
  // @media screen and (max-width:$phone) {
  //     padding-left: 23px;
  //     padding-right: 23px;
  // }
}

.contact-button:hover {
  background-color: #2c2c2c;
  transition: 0.4s;
}

.top-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.5px solid #000000;
  margin-left: 80px;
  margin-right: 80px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (max-width: $phone) {
    padding-left: 30px;
    padding-right: 30px;
    margin: 0;
  }
}

.headers {
  display: grid;
  grid-template-columns: 0.33fr 0.33fr 0.33fr;
  grid-column-gap: 15px;
  // border-bottom: 1.5px solid #E6E6E6;
  padding-bottom: 25px;
  padding-top: 45px;
  margin-left: 80px;
  margin-right: 80px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: none;
  transition: 0.3s;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media screen and (max-width: $phone) {
    padding-left: 30px;
    padding-right: 25px;
    margin: 0;
    padding-bottom: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 17px;
  }
}

.status-header {
  text-align: right;
}
.stage-header {
  @media screen and (max-width: $tablet) {
    display: none;
  }
  @media screen and (max-width: $phone) {
    display: none;
  }
}

.stage-denied-text {
  color: #888888;
}

.top-divider {
  border-bottom: 1.5px solid #e6e6e6;
}

.wrapper {
  display: grid;
  grid-template-columns: 0.4fr 0.2fr 0.4fr;
  grid-column-gap: 30px;
  border-top: 1.5px solid #e6e6e6;
  padding-bottom: 25px;
  padding-top: 25px;
  margin-left: 80px;
  margin-right: 80px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: none;
  transition: 0.3s;
  @media screen and (max-width: $tablet) {
    grid-column-gap: 50px;
    grid-template-columns: 0.5fr 0.5fr;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // align-items: center
  }
  @media screen and (max-width: $phone) {
    padding-left: 30px;
    padding-right: 25px;
    margin: 0;
    padding-bottom: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 17px;
  }
}

.wrapper:hover {
  background-color: #f2f2f2;
  transition: 0.3s;
}

.shoot {
  flex-basis: 25%;
  // padding-right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: $phone) {
    flex-basis: 50%;
  }
}

.shoot-name-title {
  display: none;
  color: #0e0e0e;
  display: flex;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  @media screen and (max-width: $phone) {
    font-size: 10px;
  }
}

.shoot-name-title-blank {
  display: none;
  margin-top: 3px;
  color: #a0a0a0;
  display: flex;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  @media screen and (max-width: $phone) {
    font-size: 10px;
  }
}

.title {
  color: #0f0f0f;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
}

.title-blank {
  color: #a0a0a0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
}

.stage-section {
  flex-basis: 15%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: black;
  @media screen and (max-width: $tablet) {
    display: none;
  }
  @media screen and (max-width: $phone) {
    display: none;
  }
}

.stage-title {
  font-size: 12px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.stage {
  margin-top: 3px;
  color: #0f0f0f;
  display: flex;
  align-items: center;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
}

.date {
  color: #0e0e0e;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.date-info {
  color: #0e0e0e;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.date-regular {
  color: #0e0e0e;
  display: flex;
  justify-content: center;
}

.date-title {
  color: #0e0e0e;
  display: flex;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  line-height: 1.3;
  @media screen and (max-width: $phone) {
    font-size: 10px;
  }
}

.date-range {
  color: #0e0e0e;
  display: flex;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: right;
  line-height: 1.3;
}

.notReady {
  color: #d4d4d4;
  display: flex;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
}

.arrow {
  display: flex;
  justify-content: center;
  padding-left: 7px;
}

.status-icon {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  font-size: 17px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #c94d4d;
}

.hold-image {
  padding-right: 5px;
  margin-top: -3px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.denied-text {
  color: #a0a0a0;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding-right: 13px;
  margin-top: 3.5px;
  text-align: right;
  @media screen and (max-width: $phone) {
    display: none;
  }
}

.completed-text {
  color: #000000;
  padding-right: 13px;
  margin-top: 3.5px;
  display: flex;
  text-align: right;
  line-height: 1.3;
  @media screen and (max-width: $phone) {
    display: none;
  }
}

.both {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stage-text {
  margin-top: -8px;
  color: #6f6f6f;
}

.stageTitle {
  margin-top: -9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-shoots-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-top: 60px;
}

.no-shoots-body {
  margin-bottom: 25px;
  text-align: center;
  padding: 0 20px 0 20px;
}

.no-shoot-button {
  width: 160px;
  height: 37px;
  background-color: black;
  transition: 0.4s;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  font-family: proxima-nova, "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 5px;
  text-decoration: none;
}

.no-shoot-button:hover {
  background-color: #2c2c2c;
  transition: 0.4s;
}
